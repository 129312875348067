export class Linija {
coordinates: number[][];
opis: string;
strokeColor: string;
strokeOpacity: number;
strokeWeight: number;
label: string;
info: string;
detalji: any;
}
