<mat-dialog-content>
  <table mat-table [dataSource]="lista" class="mat-elevation-z8">

    <!-- Position Column -->
    <ng-container matColumnDef="key">
      <th mat-header-cell *matHeaderCellDef>
        <button mat-button [mat-dialog-close]="true">
          <mat-icon>keyboard_backspace</mat-icon>
        </button>
      </th>
      <td mat-cell *matCellDef="let element"> {{element.key}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="value">
      <th mat-header-cell *matHeaderCellDef> </th>
      <td mat-cell *matCellDef="let element"> {{element.value}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>